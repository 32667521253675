import { Map, Marker } from 'react-map-gl'
import React, { useEffect, useReducer, useState } from 'react'
import Chart from 'react-apexcharts'

import Avatar          from '@components/Avatar'
import Callout         from '@components/Callout'
import MultiselectItem from '@components/Form/Select/MultiselectItem'
import Thumbnail       from '@components/Form/FileUploader/Thumbnail'

import { useGlobalContextState } from '@context/GlobalContext'

import { MEASURE_ACTIONS, measureReducer } from '@reducers/measureReducer'

import IMeasure from '@interfaces/IMeasure.d'

interface MeasureProps {
  measure: IMeasure
}

const MeasureDetails: React.FC<MeasureProps> = ({ measure }) => {

  const { i18n, fetchApi, CONSTANTS } = useGlobalContextState()

  const { MEASURMENT } = CONSTANTS

  const [measurment, setMeasurment] = useState(measure)

  const [_measureState, measureDispatch] = useReducer(measureReducer, { measure, fetchApi })

  useEffect(() => measureDispatch({ type: MEASURE_ACTIONS.FETCH_MEASURE, measure, callbacks: [response => setMeasurment(response.response)], params: { values: true } }), [])

  // const annotations = useMemo(() => {
  //   return measure.rules.map(raw_rule => {
  //     const rule = JSON.parse(raw_rule)
  //     let margin

  //     switch(rule.type) {
  //       case MEASURMENT.RULES.BIGGER_THAN:  margin = Number.parseInt(rule.limit, 10) + Number.parseInt(rule.margin, 10); break
  //       case MEASURMENT.RULES.SMALLER_THAN: margin = Number.parseInt(rule.limit, 10) - Number.parseInt(rule.margin, 10); break
  //     }

  //     return ({
  //       y:           Number.parseInt(rule.limit, 10),
  //       borderColor: 'var(--rep-danger)',
  //       y2:          margin,
  //       fillColor:   'var(--rep-danger)',
  //       label:       {
  //         text: `Limit: < ${rule.limit} ~ ${rule.margin} `
  //       }
  //     })
  //   })
  // }, [measure])

  // const options = useMemo(() => ({
  //   xaxis: {
  //     labels:     { show: false },
  //     axisBorder: { show: false },
  //     // crosshairs: { show: false },
  //     axisTicks:  { show: false },
  //     type:       'category',
  //     categories: measurment.values.map(v => new Intl.DateTimeFormat(i18n.locale).format(new Date(v.date)))
  //   },
  //   yaxis: {
  //     // labels: { show: false },
  //   },
  //   chart: {
  //     toolbar: {
  //       tools: {
  //         download:  true,
  //         selection: false,
  //         zoom:      false,
  //         zoomin:    false,
  //         zoomout:   false,
  //         pan:       false,
  //         reset:     false
  //       },
  //     },
  //     legend: {
  //       show: false
  //     }
  //   },
  //   grid: {
  //     // show: false
  //   },
  //   tooltip: {
  //     x: { show: false },
  //     y: { show: false }
  //     // enabled: false
  //     // marker: { show: false }
  //   },

  //   dataLabels: {
  //     enabled: true
  //   },
  //   stroke: {
  //     width:  1,
  //     colors: ['var(--rep-primary)'],
  //     curve:  'stepline',

  //   },
  //   annotations: {
  //     yaxis:  annotations,
  //     points: [{
  //       x:      '03/07/2024',
  //       y:      410,
  //       marker: {
  //         size:        8,
  //         fillColor:   'red',
  //         strokeColor: 'red',
  //         radius:      2,
  //       }
  //     }],
  //   },
  //   fill: {
  //     type:     'gradient',
  //     colors:   ['var(--rep-primary)'],
  //     gradient: {
  //       gradientToColors: ['white', 'var(--rep-primary-light)'],
  //       shadeIntensity:   1,
  //       opacityFrom:      1,
  //       opacityTo:        0.5,
  //     }
  //   },
  // }), [measurment])

  // const series = useMemo(() => [
  //   {
  //     color: 'var(--rep-primary)',
  //     name:  'series-12',
  //     data:  measurment.values.map(v => v.content, 10)
  //   }
  // ], [measurment])

  const parsedValue = measurmentValue => {
    switch(measurment.type) {
      case MEASURMENT.TYPES.NUMBER:    return measurmentValue.content
      case MEASURMENT.TYPES.FREE_TEXT: return measurmentValue.content
      case MEASURMENT.TYPES.LOCATION:  return measurmentValue.content.toString()
    }
  }

  return(
    <div>
      {/* {measurment && measurment.type === MEASURMENT.TYPES.NUMBER &&
        <Chart
          options = {options}
          series  = {series}
          type    = 'area'
          // width   = '100%'
          style   = {{ borderRadius: '5px' }}
          height  = '280px'
          width   = '100%'
        />
      } */}
      {measurment && measurment.type === MEASURMENT.TYPES.LOCATION &&
        <Map
          mapboxAccessToken = 'pk.eyJ1IjoiYmVydHJhbmRtb3JlbCIsImEiOiJjbGN1Z2o5a3cwbmk1M3dxdG91azJjZmZxIn0.mKd_D3yQCWmGnLC6TAvITw'
          initialViewState  = {{
            longitude: measure.last_value.content[1],
            latitude:  measure.last_value.content[0],
            // zoom:      12
          }}
          style = {{
            // position:     'absolute',
            borderRadius: '4px',
            top:          0,
            bottom:       0,
            width:        '100%',
            height:       '500px',
          }}
          mapStyle           = "mapbox://styles/mapbox/light-v11"
          attributionControl = {false}
        >
          {measurment.values.map(value =>
            <Marker
              key       = {value.id}
              longitude = {value.content[1]}
              latitude  = {value.content[0]}
              anchor    = "bottom"
              color     = {'var(--rep-primary)'}
              offset    = {[0, 0]}
            />
          )}
        </Map>
      }

      {measurment.values.reverse().map(value =>
        <MultiselectItem
          key       = {value.id}
          // name      = {}
          colorName = 'var(--rep-neutral-primary)'
        >
          {parsedValue(value)}
          <div style={{display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'center'}}>
            {value.comment
              ? <Callout
                type  = "primary"
                title = {i18n.t('measurment.comment')}
              >
                {value.comment}
              </Callout>
              : <div></div>
            }
            {value.user &&
            <Avatar
              firstName  = {value.user.first_name}
              lastName   = {value.user.last_name}
              background = 'transparent'
              border     = 'var(--rep-neutral-primary-light)'
              color      = 'var(--rep-neutral-primary)'
              title      = {i18n.t('control_remarks.created_on', {date: new Date(value.date).toLocaleDateString(i18n.locale)})}
            />
            }
          </div>
          <div style={{display: 'flex' }}>
            {value.documents.map(file =>
              <Thumbnail
                key  = {file.id}
                file = {file}
                size = '50px'
              />
            )}
          </div>
        </MultiselectItem>
      )}
    </div>
  )
}

export default MeasureDetails
